.massagesWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 12px;
  margin: 15px;
  background-image: none;
  overflow: hidden;
  max-height: 600px;
  background-color: gainsboro;
  border: 1px solid gainsboro;
}

.usersListWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid rgb(194, 194, 194);
  background-color: white;
}

.searchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 15px;
  background-color: gainsboro;
}

.searchUserInput {
  width: 100%;
  height: 25px;
  border: 0;
  border-radius: 12px;
  outline: none;
  padding-left: 35px;
}

.searchIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
}

.usersList {
  overflow-y: auto;
}

.usersList::-webkit-scrollbar {
  width: 10px;
}

.usersList::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.userItem {
  padding: 15px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-bottom: 0.5px solid rgb(208, 208, 208);
  min-width: 190px;
}

.userItemLeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.userImgWrapper {
  min-width: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #f57c00;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 10px;
}

.userImage {
  width: 105%;
  height: 105%;
}

/* .userInfoWrapper {
} */

.chatWrapper {
  display: flex;
  flex: 3;
  flex-direction: column;
  min-width: 450px;
}

.chatHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 67px;
  width: 100%;
}

.chatReceiverWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.receiverImgWrapper {
  display: flex;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  border-radius: 50px;
  overflow: hidden;
  align-items: center;
  margin-left: 30px;
  justify-content: center;
  background-color: #f57c00;
}

.receiverImg {
  width: 105%;
  height: 105%;
}

.receiverName {
  margin-left: 10px;
  margin-right: 10px;
}

.chatHeaderButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 30px;
}

.chatSearchIcon {
  width: 20px;
  height: 20px;
}

.chatBodyWrapper {
  height: 475px;
  background-color: #f57c00;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.chatBody {
  overflow-y: auto;
  padding: 10px 15px;
}

.chatBody::-webkit-scrollbar {
  width: 0;
}

.msgsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.singleMsgWrapper {
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  min-width: 75px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  max-width: 50%;
}

.singleMsgWrapperFromMe {
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  min-width: 75px;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  max-width: 50%;
}

/* .msgText {
} */

.msgTime {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-size: 10px;
  margin-top: 5px;
}

.chatBottomWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 58px;
}

.smileIconWrapper {
  width: 35px;
  height: 30px;
  overflow: hidden;
  margin-left: 25px;
}

.smileIcon {
  width: 100%;
  height: 100%;
}

.newMsgInput {
  width: 100%;
  height: 25px;
  border: 0;
  border-radius: 12px;
  outline: none;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 15px;
}

.searchMessageInputWrapper {
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchMessageInput {
  width: 100%;
  height: 25px;
  border: 0;
  border-radius: 12px;
  outline: none;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 15px;
}

.searchCloseIconWrapper {
  width: 17px;
  height: 15px;
  margin-bottom: 3px;
}

.searchCloseIcon {
  width: 100%;
  height: 100%;
}

.attachIconWrapper {
  width: 25px;
  height: 23px;
  margin-left: 10px;
}

.attachIcon {
  width: 100%;
  height: 100%;
}

.userItem:hover {
  opacity: 0.5;
}

.userItemName {
  font-weight: 600;
  font-size: 12px;
}

.userItemLastMsg {
  font-size: 10px;
  margin-top: 5px;
}

.userItemLastMsgTime {
  font-size: 8px;
  min-width: 50px;
  max-width: 50px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-end;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.initials {
  color: white;
}

.userItemNameWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.adminMsgWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.singleAdminMsgWrapper {
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  min-width: 75px;
  border-radius: 10px;
  max-width: 50%;
}

.adminMsgTitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

/* .adminMsg {
} */

.adminMsgTime {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-size: 10px;
  margin-top: 5px;
}

.dayWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  color: azure;
  font-size: 14px;
  margin-bottom: 20px;
}

.unreadMessage {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #f57c00;
}

.userItemLastMsgWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.uploadImageFileWrapper {
  position: absolute;
  margin-left: 10px;
  margin-bottom: 240px;
  padding: 3px;
  background-color: gainsboro;
  border-radius: 5px;
}

.uploadDocFileWrapper {
  position: absolute;
  margin-left: 10px;
  margin-bottom: 120px;
  padding: 10px;
  background-color: gainsboro;
  border-radius: 5px;
}

.closeImage {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.closeImageDoc {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.uploadImage {
  width: 150px;
  height: 150px;
}

.fileImage {
  height: 35px;
}

.uploadDocWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.uploadDocName {
  margin-left: 5px;
  margin-right: 15px;
}

.fileImageSingleMsg {
  height: 35px;
  margin-bottom: 5px;
}

.uploadDocNameSingleMsg {
  color: black;
  margin-left: 5px;
  margin-right: 10px;
}

.imageSingleMsg {
  width: 100px;
  height: 150px;
  /* max-width: 200px;
  max-height: 350px; */
}

.msgSender {
  margin-bottom: 5px;
  font-size: 12px;
}
