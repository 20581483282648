body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 1315px) {
  .makeStyles-wrapper-47 {
    left: -200px;
  }
}

@media screen and (max-width: 1120px) {
  .makeStyles-wrapper-47 {
    left: -350px;
  }
}

@media screen and (max-width: 970px) {
  .makeStyles-wrapper-47 {
    left: -500px;
  }
}

@media screen and (max-width: 840px) {
  .makeStyles-wrapper-47 {
    left: -250px;
  }
}
